import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['entity.make', 'entity.model', 'entity.bodies'],
    entity: {
      make: {
        required,
        maxLength: maxLength(20)
      },
      model: {
        required,
        maxLength: maxLength(100)
      },
      bodies: {
        required,
        maxLength: maxLength(12)
      }
    },
    filter: {}
  }
}
