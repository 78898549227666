<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-8">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Detail Info</p>
        <div class="field">
          <label class="label">Make</label>
          <div class="control is-expanded desc-width">
            <multiselect
              :class="{ 'is-danger' : $v.entity.make.$error}"
              v-model="selectedMake"
              deselect-label="Can't remove this value"
              track-by="make"
              label="make"
              :options="makes"
              :loading="isMakeLoading"
              :internal-search="false"
              :options-limit="makeFilter.pageSize"
              placeholder="Search or Add Make"
              @select="selectMake"
              @remove="removeMake"
              @search-change="getMakeDropdownFilter"
              :searchable="true"
              :show-labels="false"
              :allow-empty="true">
              <template
                slot="option"
                slot-scope="props">
                <span>{{ props.option.make }}</span>
              </template>
              <span
                class="has-text-danger"
                slot="noResult">Make not found.</span>
            </multiselect>
            <span
              class="help is-danger"
              v-if="$v.entity && $v.entity.make.$error">Make is required.</span>
          </div>
        </div>
        <div class="field">
          <label class="label">Model</label>
          <div class="control desc-width">
            <input
              name="model"
              class="input is-capitalized"
              type="text"
              placeholder="Model"
              v-model="value.model"
              :class="{'is-danger' : $v.entity.model.$error}"
              maxlength="100">
            <span
              class="help is-danger"
              v-if="$v.entity && !$v.entity.model.required">Model is required.</span>
          </div>
        </div>
        <div class="field">
          <label class="label">Cylinders</label>
          <div class="field-body">
            <div class="control">
              <vue-numeric
                class="input has-text-right"
                :precision="0"
                maxlength="8"
                v-model.number="value.cylinders" />
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Production Year</label>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field has-addons">
                <p class="control">
                  <button
                    class="button is-static"
                    disabled>From</button>
                </p>
                <p class="control">
                  <span class="select">
                    <select v-model="value.startMonth">
                      <option
                        value="null"
                        disabled>MM</option>
                      <option
                        v-for="(m, key) in months"
                        :value="key"
                        :key="key">{{ m }}</option>
                    </select>
                  </span>
                </p>
                <p class="control">
                  <the-mask
                    class="input"
                    placeholder="e.g. 2000"
                    v-model="value.startYear"
                    :mask="['####']"
                    @blur.native="checkStartYear" />
                </p>
                <p class="control">
                  <button
                    class="button is-static"
                    disabled>To</button>
                </p>
                <p class="control">
                  <span class="select">
                    <select v-model="value.endMonth">
                      <option
                        value="null"
                        disabled>MM</option>
                      <option
                        v-for="(m, key) in months"
                        :value="key"
                        :key="key">{{ m }}</option>
                    </select>
                  </span>
                </p>
                <p class="control">
                  <the-mask
                    class="input"
                    placeholder="e.g. 2000"
                    v-model="value.endYear"
                    :mask="['####']"
                    @blur.native="checkEndYear" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="is-divider" />
        <div class="field">
          <div class="buttons has-addons is-centered">
            <span
              class="button"
              :class="{ 'is-success is-selected' : value.active }"
              @click="toggleActive(true)">
              <span class="icon is-small">
                <i class="mdi mdi-check" />
              </span>
              <span>Active</span>
            </span>
            <span
              class="button"
              :class="{ 'is-danger is-selected' : !value.active }"
              @click="toggleActive(false)">
              <span class="icon is-small">
                <i class="mdi mdi-close" />
              </span>
              <span>In-Active</span>
            </span>
          </div>
        </div>
      </article>
    </div>
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Body Styles</p>
        <div class="field is-horizontal">
          <label class="label">Bodies&nbsp;&nbsp; <small>({{ picked }}/{{ pickBodyStyles.length }} Selected)</small> </label>
        </div>
        <span
          class="help is-danger"
          v-if="$v.entity && !$v.entity.bodies.required">Bodies is required</span>
        <div class="field">
          <ul>
            <li
              v-for="(b, index) in pickBodyStyles"
              :key="index">
              <input
                type="checkbox"
                class="is-checkradio"
                :id="index"
                v-model="b.selected">
              <label :for="index">{{ b.name }}</label>
            </li>
          </ul>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash.debounce'
import UnscheduledModelService from './UnscheduledModelService'
import Multiselect from 'vue-multiselect'
import VueNumeric from '@/components/VueNumeric'
import { FocusInserted } from '@/components/directives'
import StoreMixin from './storeMixin'

export default {
  name: 'UnscheduledmodelDetail',
  inject: ['$vv'],
  components: {
    VueNumeric,
    Multiselect
  },
  directives: {
    FocusInserted
  },
  mixins: [StoreMixin],
  props: {
    value: null,
    isNew: Boolean
  },
  data() {
    return {
      months: {
        1: '01',
        2: '02',
        3: '03',
        4: '04',
        5: '05',
        6: '06',
        7: '07',
        8: '08',
        9: '09',
        10: '10',
        11: '11',
        12: '12'
      },
      pickBodyStyles: [],
      makes: [],
      selectedMake: null,
      isMakeLoading: false,
      makeFilter: {
        make: '',
        pageIndex: 1,
        pageSize: 50
      }
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    bodyStyles: function() {
      return this.storeBodyShapes
    },
    picked: function() {
      return this.pickBodyStyles.filter(d => d.selected).length
    },
    selectedBodies: function() {
      return this.pickBodyStyles.filter(d => d.selected).map(d => d.id)
    }
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedBodies: {
      handler: function(newVal, oldVal) {
        var strBodies = ''
        for (let i = 0; i < newVal.length; i++) {
          strBodies = strBodies + newVal[i]
        }
        this.value.bodies = strBodies
      },
      deep: true
    }
  },
  created() {
    this.makeFilter.make = this.value.make
    this.getMakeDropdown()
    this.pickBodyStyles = this.bodyStyles.map(d => {
      return {
        id: d.id,
        name: d.name
      }
    })
  },
  mounted() {
    this.$v.detailGroup.$touch()
    if (!this.value.isNew && this.value.bodies != null) {
      this.pickBodyStyles = this.bodyStyles.map(d => {
        return {
          selected: this.value.bodies.indexOf(d.id) > -1,
          id: d.id,
          name: d.name
        }
      })
    }
  },
  methods: {
    checkStartYear(e) {
      this.value.startYear = this.getYear(e.target.value)
    },
    checkEndYear(e) {
      this.value.endYear = this.getYear(e.target.value)
    },
    getYear: function(year) {
      if (year.length < 4) {
        const strYear = `01/01/${year}`
        const newDate = new Date(strYear)
        return newDate.getFullYear()
      } else {
        return year
      }
    },
    toggleActive(active) {
      this.value.active = active
      if (!active) {
        this.checkInUse()
      }
    },
    checkInUse() {
      this.$emit('entityDelete')
    },
    async getMakeDropdown() {
      this.isMakeLoading = true
      this.makes = await UnscheduledModelService.getMakeDropdownProto(this.makeFilter)
      if (this.makeFilter.make !== '') {
        var findMake = this.makes.find(c => c.make === this.value.make.toUpperCase())
        if (findMake != null) {
          this.selectedMake = findMake
        } else {
          // edit for inactive model when make is not in dropdown (only active Make will display in the dropdown)
          const tag = {
            rowNumber: 1,
            make: this.makeFilter.make,
            totalRows: 1
          }
          this.makes.splice(0, 0, tag)
          this.makeFilter.make = tag.make
          this.selectedMake = tag
        }
      }
      this.isMakeLoading = false
    },
    getMakeDropdownFilter: _debounce(async function(query) {
      this.isMakeLoading = true
      if (query != '') {
        this.makeFilter.make = query
      }
      this.makes = await UnscheduledModelService.getMakeDropdownProto(this.makeFilter)
      var findMake = this.makes.find(c => c.make === query.toUpperCase())
      if (this.makes == '' || findMake == null) {
        const tag = {
          rowNumber: 0,
          make: this.makeFilter.make.toUpperCase(),
          totalRows: 0
        }
        // this.makes.push(tag)
        this.makes.splice(0, 0, tag) // insert the item to array at first
        this.value.make = tag.make
        this.makeFilter.make = tag.make
      }
      this.isMakeLoading = false
    }, 500),
    async selectMake(selected) {
      this.value.make = selected.make
      this.makeFilter.make = selected.make
      this.make = await UnscheduledModelService.getMakeDropdownProto(this.makeFilter)
      this.$v.entity.make.$touch()
    },
    removeMake() {
      this.makeFilter.make = ''
      this.value.make = ''
      this.$v.entity.make.$touch()
      this.getMakeDropdown()
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.desc-width {
  width: 60%;
}
</style>
